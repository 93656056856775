"use client";
import React, { VFC } from "react";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { getTestAutomationProps } from "~/helpers/test-automation-props";
import { getFormatMedia } from "~/theme/utils";

import { HomePageCategoriesProps } from "./types";

export const homePageCategories = "home-page-categories";

export const HomePageCategories: VFC<HomePageCategoriesProps> = ({
  categories = [],
  isHomePageBannerDisplayed,
}) => {
  const theme = useTheme();
  const formatMedia = getFormatMedia(theme);
  const isDesktop = useMediaQuery(formatMedia.BREAKPOINT_DESKTOP);

  if (!categories?.length) {
    return null;
  }

  return (
    <Container
      {...getTestAutomationProps(homePageCategories)}
      sx={{ position: "relative" }}
    >
      <Grid
        container
        justifyContent={`${isDesktop ? "center" : "flex-start"}`}
        sx={{
          marginTop: {
            xs: isHomePageBannerDisplayed ? -2 : 0,
            sm: isHomePageBannerDisplayed ? -3 : 0,
          },
        }}
      >
        {categories?.map((item, index) => (
          <Grid
            sx={{ marginTop: { md: isHomePageBannerDisplayed ? -3 : 0 } }}
            item
            xs={6}
            sm={4}
            md={2}
            key={index}
          >
            {item}
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};
