import { Account } from "~/bff/types/Account";
import { MyAccountPage } from "~/bff/types/MyAccountPage";
import { InformationPopUpProps } from "~/components/pop-up/components/information-pop-up/types";

import { paymentCardsPropsMocked } from "../payment-cards-page/components/payment-card/mock";

export const mockAccount: Account = {
  firstName: "Monica",
  lastName: "Doe",
  email: "monica@gmail.com",
  marketingCommunicationOptInPc: true,
  preferredStore: "London",
  paymentMethods: paymentCardsPropsMocked,
};

export const mockNotSubscribedAccount: Account = {
  firstName: "Monica",
  lastName: "Doe",
  email: "monica@gmail.com",
  marketingCommunicationOptInPc: false,
  preferredStore: "London",
  paymentMethods: paymentCardsPropsMocked,
  verifiedOptInTrigger: false,
};

export const mockAccountPendingSubscription: Account = {
  firstName: "Monica",
  lastName: "Doe",
  email: "monica@gmail.com",
  marketingCommunicationOptInPc: false,
  preferredStore: "London",
  paymentMethods: paymentCardsPropsMocked,
  verifiedOptInTrigger: true,
};

export const accountPagePropsMocked: MyAccountPage = {
  component: "MyAccountPage",
  props: {
    navigation: {
      component: "MyAccountNavigation",
      props: {
        welcomeText: "Welcome, {{First name}}!",
        profileText: "Profile",
        shoppingListText: "Shopping List",
        logOutText: "Log Out",
        orderHistoryText: "Order History",
        billingAddressText: "Billing Address",
        paymentCardsText: "Payment Card",
      },
    },
    translations: {
      FAQLink: {
        label: "Visit FAQ",
        url: "https://primark.com/en-gb/",
      },
      optInLegalText:
        "By opting in you confirm that you have read our Privacy Notice and agree to receive personalised communications from Primark. Our [Privacy Notice](https://privacy.link/) explains who we are and how we use your data. You may unsubscribe at any time.",
      mainTitle: "My Account",
      pageTitle: "My Profile",
      emailSubtitle: "Email",
      firstNameSubtitle: "First name",
      lastNameSubtitle: "Last name",
      dateOfBirthSubtitle: "Date of birth",
      changePasswordText: "Change password",
      FAQText: "Need help?",
      optInTitle: "Subscribe for product news and inspiration",
      optInHeader: "Newsletter preferences",
      unsubscribePopUp: {
        mainTitle: "Unsubscribe",
        mainDescription:
          "You will be unsubscribed from product news and inspiration. You can re-subscribe at any time again.",
        unsubscribeMeButtonText: "Unsubscribe me",
        keepMeSubscribedButtonText: "Keep me subscribed",
        unsubscribedTitle: "Successfully Unsubscribed",
        unsubscribedDescription:
          "You have successfully unsubscribed from Primark newsletters.",
        continueButtonText: "Continue",
      },
      preferredStoreFields: {
        title: "Preferred Store",
        dropdownPlaceholder: "Choose preferred store",
      },
      editProfile: {
        editProfileButtonText: "Edit Profile",
        title: "Edit profile",
        saveButtonText: "Save changes",
        cancelButtonText: "Cancel",
        errorMessages: {
          firstNameEmpty: "This field is required.",
          lastNameEmpty: "This field is required.",
          firstNameMinLength: "First name must contain between 2 to 40 characters.",
          lastNameMinLength: "Last name must contain between 2 to 40 characters.",
          firstNameMaxLength: "First name must contain between 2 to 40 characters.",
          lastNameMaxLength: "Last name must contain between 2 to 40 characters.",
        },
      },
    },
  },
};

export const changePasswordPopupPropsMocked: InformationPopUpProps = {
  title: "Password Changed",
  description: "Your password has been changed.",
  actionButtonText: "Continue",
};

export const resetPasswordPopupPropsMocked: InformationPopUpProps = {
  title: "Password reset",
  description: "Your password was successfully reset.",
  actionButtonText: "Continue",
};
