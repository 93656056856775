import { ImageTemplates } from "~/components/image/types";
import { WindowFormat } from "~/context/breakpoints/types";

export const imageTemplates: ImageTemplates = {
  [WindowFormat.mobile]: "homepagebanner-mobile",
  [WindowFormat.tablet]: "homepagebanner-tablet",
  [WindowFormat.desktop]: "homepagebanner-regulardesktop",
  [WindowFormat.wideDesktop]: "homepagebanner-largedesktop",
};

export const HOME_PAGE_BANNER_PREFIX = "HomePageBanner";

export const HOME_PAGE_BANNER_CLASSES = {
  banner: `${HOME_PAGE_BANNER_PREFIX}-banner`,
  container: `${HOME_PAGE_BANNER_PREFIX}-container`,
  textBlock: `${HOME_PAGE_BANNER_PREFIX}-textBlock`,
  leftMarginDesktop: `${HOME_PAGE_BANNER_PREFIX}-leftMarginDesktop`,
  title: `${HOME_PAGE_BANNER_PREFIX}-title`,
  header: `${HOME_PAGE_BANNER_PREFIX}-header`,
  backgroundImage: `${HOME_PAGE_BANNER_PREFIX}-backgroundImage`,
};
