import { ImageTemplates } from "~/components/image/types";
import { WindowFormat } from "~/context/breakpoints/types";

export const imageTemplates: ImageTemplates = {
  [WindowFormat.mobile]: "categoriesimages-mobile",
  [WindowFormat.tablet]: "categoriesimages-tablet",
  [WindowFormat.desktop]: "categoriesimages-regulardesktop",
  [WindowFormat.wideDesktop]: "categoriesimages-largedesktop",
};

export const NAV_ITEM_COMPONENT_PREFIX = "NavItemComponent";

export const NAV_ITEM_COMPONENT_CLASSES = {
  title: `${NAV_ITEM_COMPONENT_PREFIX}-title`,
  textBlock: `${NAV_ITEM_COMPONENT_PREFIX}-textBlock`,
  backgroundImage: `${NAV_ITEM_COMPONENT_PREFIX}-backgroundImage`,
};
