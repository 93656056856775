"use client";
import React, { CSSProperties } from "react";

import { BoxProps } from "@mui/material";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

import { COLOR_BLACK } from "~/theme/colors";
import { TypographyBodyClass } from "~/theme/typography";
import { getFormatMedia } from "~/theme/utils";

import { CLICK_AND_COLLECT_HERO_BANNER_CLASSES } from "./constants";

export const ClickAndCollectHeroBannerContainer = styled(
  ({ ...props }: BoxProps) => <Box {...props} />,
)(({ theme }) => {
  const { BREAKPOINT_TABLET, BREAKPOINT_DESKTOP } = getFormatMedia(theme);
  return {
    paddingBottom: theme.spacing(7),
    [BREAKPOINT_TABLET]: {
      paddingBottom: theme.spacing(9),
    },
    [BREAKPOINT_DESKTOP]: {
      paddingBottom: theme.spacing(16),
    },
    [`& .${CLICK_AND_COLLECT_HERO_BANNER_CLASSES.heroBanner}`]: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      padding: theme.spacing(4, 2),
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "cover",
      position: "relative",
      [BREAKPOINT_TABLET]: {
        alignItems: "center",
        flexDirection: "row",
        columnGap: theme.spacing(3),
        justifyContent: "space-between",
        padding: theme.spacing(6, 4),
      },
      [BREAKPOINT_DESKTOP]: {
        padding: `${theme.spacing(6)} 12vw`,
      },
    },
    [`& .${CLICK_AND_COLLECT_HERO_BANNER_CLASSES.bannerImageWrapper}`]: {
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
    },
    [`& .${CLICK_AND_COLLECT_HERO_BANNER_CLASSES.bannerBackground}`]: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
    [`& .${CLICK_AND_COLLECT_HERO_BANNER_CLASSES.leftSection}`]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      zIndex: 1,
    },
    [`& .${CLICK_AND_COLLECT_HERO_BANNER_CLASSES.logoImage}`]: {
      width: 185,
      height: 40,
      flex: "0 0 auto",
      display: "block",
      marginBottom: theme.spacing(4),
      [BREAKPOINT_DESKTOP]: {
        width: 232,
        height: 50,
        marginBottom: theme.spacing(6),
      },
    },
    [`& .${CLICK_AND_COLLECT_HERO_BANNER_CLASSES.bannerTitle}`]: {
      ...theme.typography.h2,
      marginBottom: theme.spacing(3),
      color: COLOR_BLACK,
      maxWidth: 422,
      [BREAKPOINT_TABLET]: {
        marginBottom: 0,
      },
      [BREAKPOINT_DESKTOP]: {
        ...theme.typography.h1,
      },
    },
    [`& .${CLICK_AND_COLLECT_HERO_BANNER_CLASSES.bannerButtonLabel}`]: {
      fontWeight: theme.typography.fontWeightBold as number,
    },
    [`& .${CLICK_AND_COLLECT_HERO_BANNER_CLASSES.bannerButton}`]: {
      alignSelf: "stretch",
      marginTop: theme.spacing(3),
      fontWeight: theme.typography.fontWeightBold as number,
      [BREAKPOINT_TABLET]: {
        alignSelf: "flex-start",
        marginTop: theme.spacing(4),
      },
      [BREAKPOINT_DESKTOP]: {
        marginTop: theme.spacing(6),
      },
    },
    [`& .${CLICK_AND_COLLECT_HERO_BANNER_CLASSES.rightSection}`]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      rowGap: theme.spacing(2),
      zIndex: 1,
      [BREAKPOINT_TABLET]: {
        rowGap: theme.spacing(3),
      },
      [BREAKPOINT_DESKTOP]: {
        rowGap: 0,
      },
    },
    [`& .${CLICK_AND_COLLECT_HERO_BANNER_CLASSES.bannerImageBlock}`]: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      columnGap: theme.spacing(2),
      [BREAKPOINT_DESKTOP]: {
        "&:nth-of-type(2)": {
          paddingLeft: theme.spacing(4),
        },
        "& > svg": {
          marginRight: theme.spacing(-2),
        },
      },
    },
    [`& .${CLICK_AND_COLLECT_HERO_BANNER_CLASSES.bannerImage}`]: {
      width: 64,
      height: 64,
      flex: "0 0 auto",
      display: "block",
      aspectRatio: "1 / 1",
      [BREAKPOINT_TABLET]: {
        width: 96,
        height: 96,
      },
      [BREAKPOINT_DESKTOP]: {
        width: 128,
        height: 128,
      },
    },
    [`& .${CLICK_AND_COLLECT_HERO_BANNER_CLASSES.bannerImageDesc}`]: {
      "& > p": {
        color: COLOR_BLACK,
        ...(theme.typography.body1[
          `&.${TypographyBodyClass.articleLarge}`
        ] as CSSProperties),
      },
      [BREAKPOINT_TABLET]: {
        maxWidth: 228,
      },
    },
  };
});
