"use client";
import React from "react";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { HomePageBanner as IHomePageBanner } from "~/bff/components/HomePageBanner";
import { Image } from "~/components/image/component";
import { prepareTextAttribute } from "~/helpers/prepare-text-attribute";
import { getTestAutomationProps } from "~/helpers/test-automation-props";
import { EvergageContentZones } from "~/lib/salesforce/evergage/types";
import { getImageSrc } from "~/utils/image/get-image-src";

import { HOME_PAGE_BANNER_CLASSES, imageTemplates } from "./constants";
import { HomePageBannerContainer } from "./styled";

export const HomePageBanner: IHomePageBanner = ({
  title,
  text,
  image,
  textColor,
  testAutomationId = "home-page-banner",
}) => {
  return (
    <HomePageBannerContainer
      textColor={textColor ?? ""}
      {...getTestAutomationProps(testAutomationId)}
      data-evergage-zone={EvergageContentZones.HOME_HERO_BANNER}
    >
      <Box className={HOME_PAGE_BANNER_CLASSES.banner}>
        <Image
          className={HOME_PAGE_BANNER_CLASSES.backgroundImage}
          imageTemplates={imageTemplates}
          src={getImageSrc(image?.source)}
          alt={prepareTextAttribute(image?.alt, true)}
          title={prepareTextAttribute(image?.title)}
          aria-hidden={true}
          fetchPriority="high"
        />
        <Container className={HOME_PAGE_BANNER_CLASSES.container}>
          <Box
            className={HOME_PAGE_BANNER_CLASSES.textBlock}
            {...getTestAutomationProps("text")}
          >
            <Grid container>
              <Grid
                item
                lg={1}
                className={HOME_PAGE_BANNER_CLASSES.leftMarginDesktop}
              />
              <Grid item xs={12} sm={8} md={5} lg={4}>
                <Typography
                  variant="h1Regular"
                  className={HOME_PAGE_BANNER_CLASSES.title}
                >
                  {title}
                </Typography>
                <Typography className={HOME_PAGE_BANNER_CLASSES.header}>
                  {text}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </HomePageBannerContainer>
  );
};
