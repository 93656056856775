"use client";
import React, { ReactNode, useEffect } from "react";

import { useIsAuthenticated } from "@azure/msal-react";
import dynamic from "next/dynamic";
import { useParams } from "next/navigation";

import { GlobalPathParams } from "~/app/[locale]/types";
import { HomePageProps } from "~/bff/components/HomePage";
import { resetPasswordPopupPropsMocked } from "~/components/account-page/mock";
import { HomePageContainer } from "~/components/home-page/styled";
import { HomePageCategories } from "~/components/home-page-categories/component";
import { InformationPopUpProps } from "~/components/pop-up/components/information-pop-up/types";
import { DataLayerData, EventsNames, EventTypes } from "~/constants/data-layer";
import { Variables } from "~/constants/request";
import { useVariable } from "~/context/variables/hooks/use-variable";
import { getCountryCode } from "~/helpers/country/get-country-code";
import { importComponentByName } from "~/helpers/import-component-by-name";
import { pushDataLayer } from "~/helpers/push-data-layer";
import { getTestAutomationProps } from "~/helpers/test-automation-props";
import { useChangeResetPasswordPopup } from "~/hooks/use-change-reset-password-popup";

const InformationPopUp = dynamic(async () =>
  importComponentByName(
    import("~/components/pop-up/components/information-pop-up/component"),
    "InformationPopUp",
  ),
);

export const HomePage: React.FC<HomePageProps> = ({
  banner,
  categories,
  children,
  seo,
  sessionExpiredPopUp,
  testAutomationId = "home-page",
}) => {
  const { locale } = useParams<GlobalPathParams>();
  const isUserAuthenticated = useIsAuthenticated();
  const pageTitle = useVariable(Variables.PAGE_TITLE);
  const { isPasswordReset, closePasswordResetPopup } = useChangeResetPasswordPopup();

  const passwordResetPopupProps: InformationPopUpProps = {
    //TODO remove mocks after https://team-1626860771808.atlassian.net/browse/OSCWEB-21487 is done
    ...resetPasswordPopupPropsMocked,
    open: isPasswordReset,
    handleClose: closePasswordResetPopup,
    handleActionButtonClick: closePasswordResetPopup,
  };

  useEffect(() => {
    const country = getCountryCode(locale);
    const eventData = {
      [DataLayerData.event]: EventsNames.homepage,
      [DataLayerData.siteCountry]: country,
      [DataLayerData.type]: EventTypes.homepage,
      [DataLayerData.pageCategory]: "homepage",
      [DataLayerData.loggedInStatus]: isUserAuthenticated,
    };
    pushDataLayer(eventData);
  }, [isUserAuthenticated, locale]);

  return (
    <HomePageContainer {...getTestAutomationProps(testAutomationId)}>
      <h1 style={{ display: "none" }}>{`${pageTitle}`}</h1>
      {(seo?.jsonLd as ReactNode) ?? null}
      {(banner as ReactNode) ?? null}
      {(sessionExpiredPopUp as ReactNode) ?? null}
      <HomePageCategories
        isHomePageBannerDisplayed={!!banner}
        categories={categories as ReactNode[]}
      />
      <InformationPopUp {...passwordResetPopupProps} />
      {children as ReactNode[]}
    </HomePageContainer>
  );
};
