import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

import { getFormatMedia } from "~/theme/utils";

export const HomePageContainer = styled(Box)(({ theme }) => {
  const { BREAKPOINT_TABLET, BREAKPOINT_DESKTOP, BREAKPOINT_LARGE_DESKTOP } =
    getFormatMedia(theme);
  return {
    paddingBottom: theme.spacing(6),
    maxWidth: "1920px",
    margin: "auto",
    [BREAKPOINT_TABLET]: {
      paddingBottom: theme.spacing(9),
    },
    [BREAKPOINT_DESKTOP]: {
      paddingBottom: theme.spacing(10),
    },
    [BREAKPOINT_LARGE_DESKTOP]: {
      paddingBottom: theme.spacing(16),
    },
  };
});
