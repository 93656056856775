"use client";
import React from "react";

import { BoxProps } from "@mui/material";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { CSSProperties } from "@mui/material/styles/createMixins";

import { COLOR_BLACK, COLOR_GREYSCALE_BLACK_75, COLOR_WHITE } from "~/theme/colors";
import { getFormatMedia } from "~/theme/utils";

import { HOME_PAGE_BANNER_CLASSES } from "./constants";

interface UseStylesProps {
  textColor: string;
}

export const HomePageBannerContainer = styled(
  ({ textColor, ...restProps }: UseStylesProps & BoxProps) => <Box {...restProps} />,
)(({ theme, textColor }) => {
  const formatMedia = getFormatMedia(theme);
  return {
    maxWidth: "1920px",
    margin: "auto",
    [`& .${HOME_PAGE_BANNER_CLASSES.banner}`]: {
      position: "relative",
      display: "flex",
      alignItems: "center",
      height: "auto",
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      [formatMedia.BREAKPOINT_DESKTOP]: {
        height: "550px",
        marginBottom: theme.spacing(-6),
        backgroundColor:
          textColor === "light" ? COLOR_GREYSCALE_BLACK_75 : COLOR_WHITE,
      },
    },
    [`& .${HOME_PAGE_BANNER_CLASSES.container}`]: {
      zIndex: 1,
    },
    [`& .${HOME_PAGE_BANNER_CLASSES.textBlock}`]: {
      flexGrow: 1,
      width: "100%",
      color: COLOR_BLACK,
      [formatMedia.BREAKPOINT_DESKTOP]: {
        ...theme.typography.h2,
        textTransform: "inherit",
        color: textColor === "light" ? COLOR_WHITE : COLOR_BLACK,
      },
    },
    [`& .${HOME_PAGE_BANNER_CLASSES.leftMarginDesktop}`]: {
      display: "none",
      [formatMedia.BREAKPOINT_DESKTOP]: {
        display: "block",
      },
    },
    [`& .${HOME_PAGE_BANNER_CLASSES.title}`]: {
      textTransform: "inherit",
      marginBottom: theme.spacing(3),
      color: COLOR_BLACK,
      [formatMedia.BREAKPOINT_TABLET]: {
        textTransform: "inherit",
        overflowWrap: "normal",
      },
      [formatMedia.BREAKPOINT_DESKTOP]: {
        color: textColor === "light" ? COLOR_WHITE : COLOR_BLACK,
      },
    },
    [`& .${HOME_PAGE_BANNER_CLASSES.header}`]: {
      ...theme.typography.h5,
      textTransform: "inherit",
      fontWeight: theme.typography.fontWeightRegular,
    } as CSSProperties,
    [`& .${HOME_PAGE_BANNER_CLASSES.backgroundImage}`]: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      objectFit: "cover",
      objectPosition: "top center",
      display: "none",
      [formatMedia.BREAKPOINT_DESKTOP]: {
        display: "block",
      },
    },
  };
});
