import { ImageTemplates } from "~/components/image/types";
import { WindowFormat } from "~/context/breakpoints/types";

export const bannerBackgroundImageTemplates: ImageTemplates = {
  [WindowFormat.mobile]: "banner-mobile",
  [WindowFormat.tablet]: "banner-tablet",
  [WindowFormat.desktop]: "banner-regulardesktop",
  [WindowFormat.wideDesktop]: "banner-largedesktop",
};

export const logoImageTemplates: ImageTemplates = {
  [WindowFormat.mobile]: "cclogo-mobile",
  [WindowFormat.tablet]: "cclogo-tablet",
  [WindowFormat.desktop]: "cclogo-regulardesktop",
  [WindowFormat.wideDesktop]: "cclogo-largedesktop",
};

export const bannerImageTemplates: ImageTemplates = {
  [WindowFormat.mobile]: "ccbanner1-largedesktop",
  [WindowFormat.tablet]: "ccbanner1-regulardesktop",
  [WindowFormat.desktop]: "ccbanner1-tablet",
  [WindowFormat.wideDesktop]: "ccbanner1-mobile",
};

export const CLICK_AND_COLLECT_HERO_BANNER_PREFIX = "ClickAndCollectHeroBanner";

export const CLICK_AND_COLLECT_HERO_BANNER_CLASSES = {
  heroBanner: `${CLICK_AND_COLLECT_HERO_BANNER_PREFIX}-heroBanner`,
  bannerImageWrapper: `${CLICK_AND_COLLECT_HERO_BANNER_PREFIX}-bannerImageWrapper`,
  bannerBackground: `${CLICK_AND_COLLECT_HERO_BANNER_PREFIX}-bannerBackground`,
  leftSection: `${CLICK_AND_COLLECT_HERO_BANNER_PREFIX}-leftSection`,
  logoImage: `${CLICK_AND_COLLECT_HERO_BANNER_PREFIX}-logoImage`,
  bannerTitle: `${CLICK_AND_COLLECT_HERO_BANNER_PREFIX}-bannerTitle`,
  bannerButtonLabel: `${CLICK_AND_COLLECT_HERO_BANNER_PREFIX}-bannerButtonLabel`,
  bannerButton: `${CLICK_AND_COLLECT_HERO_BANNER_PREFIX}-bannerButton`,
  rightSection: `${CLICK_AND_COLLECT_HERO_BANNER_PREFIX}-rightSection`,
  bannerImageBlock: `${CLICK_AND_COLLECT_HERO_BANNER_PREFIX}-bannerImageBlock`,
  bannerImage: `${CLICK_AND_COLLECT_HERO_BANNER_PREFIX}-bannerImage`,
  bannerImageDesc: `${CLICK_AND_COLLECT_HERO_BANNER_PREFIX}-bannerImageDesc`,
};
