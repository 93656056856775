"use client";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

import { getFormatMedia } from "~/theme/utils";

import { NAV_ITEM_COMPONENT_CLASSES } from "./constants";

export const NavItemComponentContainer = styled(Box)(({ theme }) => {
  const formatMedia = getFormatMedia(theme);
  return {
    marginTop: theme.spacing(-1),
    [formatMedia.BREAKPOINT_TABLET]: {
      marginTop: theme.spacing(-1.5),
    },
    [`& .${NAV_ITEM_COMPONENT_CLASSES.title}`]: {
      ...theme.typography.button,
      lineHeight: "1.2",
      paddingTop: theme.spacing(2),

      [formatMedia.BREAKPOINT_NARROW_PHONE]: {
        paddingBottom: theme.spacing(2),
      },
      [formatMedia.BREAKPOINT_TABLET]: {
        paddingBottom: theme.spacing(1.5),
      },
    },
    [`& .${NAV_ITEM_COMPONENT_CLASSES.textBlock}`]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    [`& .${NAV_ITEM_COMPONENT_CLASSES.backgroundImage}`]: {
      display: "block",
      position: "static",
      aspectRatio: "3/4",
      width: "100%",
      margin: "0",
      objectFit: "cover",
      objectPosition: "top",
    },
  };
});
