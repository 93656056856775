"use client";
import * as React from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { CombinedButton } from "~/components/combined-button/component";
import { CombinedLink } from "~/components/combined-link/component";
import { Image } from "~/components/image/component";
import { prepareTextAttribute } from "~/helpers/prepare-text-attribute";
import { getTestAutomationProps } from "~/helpers/test-automation-props";
import { getImageSrc } from "~/utils/image/get-image-src";

import {
  CLICK_AND_COLLECT_CATEGORY_CARDS_CLASSES,
  imageTemplates,
} from "./constants";
import { ClickAndCollectCategoryCardsContainer } from "./styled";
import { ClickAndCollectCategoryCardsProps } from "./types";

export const testIds = {
  container: "click-and-collect-category-cards",
  buttonLink: "click-and-collect-categories-button-link",
  item: "click-and-collect-categories-item",
};

export const ClickAndCollectCategoryCards = (
  props: ClickAndCollectCategoryCardsProps,
): React.ReactElement => {
  const { button, categoryCards: categories, title, subtitle } = props ?? {};

  const renderImages = () =>
    categories?.map((item) => {
      if (!item) {
        return null;
      }

      const url = `${item.categoryURL || ""}`;

      const idValue = `${testIds.item}-${item.categoryTitle
        ?.replace(/ /g, "-")
        .toLowerCase()}`;

      return (
        <Grid
          item
          xs={6}
          sm={3}
          md={3}
          key={item.categoryImage?.source?.id}
          id={idValue}
        >
          <CombinedLink href={url} underline="none">
            <Image
              className={CLICK_AND_COLLECT_CATEGORY_CARDS_CLASSES.backgroundImage}
              src={getImageSrc(item?.categoryImage?.source ?? undefined)}
              alt={prepareTextAttribute(item.categoryImage?.alt, true)}
              title={prepareTextAttribute(item.categoryImage?.title)}
              imageTemplates={imageTemplates}
            />
            <Typography
              component="h2"
              variant="button"
              className={CLICK_AND_COLLECT_CATEGORY_CARDS_CLASSES.cardTitle}
            >
              {item.categoryTitle}
            </Typography>
          </CombinedLink>
        </Grid>
      );
    });

  return (
    <ClickAndCollectCategoryCardsContainer
      {...getTestAutomationProps(testIds.container)}
      id={testIds.container}
    >
      <Typography className={CLICK_AND_COLLECT_CATEGORY_CARDS_CLASSES.eyebrow}>
        {subtitle}
      </Typography>
      <Typography
        component="h1"
        className={CLICK_AND_COLLECT_CATEGORY_CARDS_CLASSES.title}
      >
        {title}
      </Typography>
      <Grid container spacing={2}>
        {renderImages()}
      </Grid>

      <Box className={CLICK_AND_COLLECT_CATEGORY_CARDS_CLASSES.buttonContainer}>
        <CombinedButton
          variant="outlined"
          href={button?.url ?? ""}
          className={CLICK_AND_COLLECT_CATEGORY_CARDS_CLASSES.button}
          {...getTestAutomationProps(testIds.buttonLink)}
        >
          {button?.text}
        </CombinedButton>
      </Box>
    </ClickAndCollectCategoryCardsContainer>
  );
};
