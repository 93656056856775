import {
  BffComponentType as Component,
  ComponentsConfig,
} from "~/bff/ComponentsConfig";
import { ClickAndCollectCategoryCards } from "~/components/click-and-collect-category-cards/component";
import { ClickAndCollectHeroBanner } from "~/components/click-and-collect-hero-banner/component";
import { HeroBannerCarousel } from "~/components/hero-banner/components/hero-banner-carousel/component";
import { HeroBannerCarouselItem } from "~/components/hero-banner/components/hero-banner-carousel-item/component";
import { HeroBannerCategory } from "~/components/hero-banner-category/component";
import { HomePage } from "~/components/home-page/component";
import { HomePageBanner } from "~/components/home-page-banner/component";
import { NavItemComponent } from "~/components/home-page-categories/components/nav-item-component/component";

export const homePageConfig: ComponentsConfig = {
  [Component.HOME_PAGE]: {
    component: HomePage,
    overrides: {
      [Component.NAV_ITEM]: {
        component: NavItemComponent,
      },
    },
  },
  [Component.HOME_PAGE_BANNER]: {
    component: HomePageBanner,
  },
  [Component.HERO_BANNER_CATEGORY]: {
    component: HeroBannerCategory,
  },
  [Component.CLICK_COLLECT_CATEGORY_CARDS]: {
    component: ClickAndCollectCategoryCards,
    overrides: {
      [Component.NAV_ITEM]: {
        component: NavItemComponent,
      },
    },
  },
  [Component.CLICK_COLLECT_HERO_BANNER]: {
    component: ClickAndCollectHeroBanner,
  },
  [Component.HERO_BANNER_CAROUSEL]: {
    component: HeroBannerCarousel,
    overrides: {
      [Component.NAV_ITEM]: {
        component: HeroBannerCarouselItem,
      },
    },
  },
  [Component.HERO_BANNER_CAROUSEL_ITEM]: {
    component: HeroBannerCarouselItem,
  },
};
