import { PaymentCardPropsExtended } from "./component";

export const paymentCardsPropsMocked: PaymentCardPropsExtended[] = [
  {
    id: "0285e1b7-84bf-49d5-9c4e-435f758b8a8f",
    type: "card",
    maskedPan: "23234554******1234",
    cardSchema: "AMERICANEXPRESS",
    expirationDate: "27-03",
    country: "en-gb",
    psp: "barclays",
    token: "barclays token",
    billingAddress: {
      city: "Rhayader",
      country: "United Kingdom",
      firstName: "Viacheslav",
      id: "4597ffc8-39d1-413a-bdb7-c9c5d8ad9c34",
      lastName: "Tester",
      line1: "Lon, Nantmel",
      line2: "",
      postCode: "LD6 5PE",
      title: "",
    },
  },
  {
    id: "9adb6864-91e7-4668-bc84-1584e1ec2b29",
    type: "card",
    maskedPan: "23234554******1254",
    cardSchema: "Visa Elctron",
    expirationDate: "22-03",
    country: "en-gb",
    psp: "barclays",
    token: "barclays token",
    billingAddress: {
      city: "Rhayader",
      country: "United Kingdom",
      firstName: "Viacheslav",
      id: "4597ffc8-39d1-413a-bdb7-c9c5d8ad9c34",
      lastName: "Tester",
      line1: "Lon, Nantmel",
      line2: "",
      postCode: "LD6 5PE",
      title: "",
    },
  },
  {
    id: "9ddb6864-91e7-4668-bc84-1584e1ec2b29",
    maskedPan: "23234554******4234",
    cardSchema: "MASTERCARD",
    expirationDate: "24-03",
    country: "en-gb",
    psp: "barclays",
    token: "barclays token",
    billingAddress: {
      city: "Rhayader",
      country: "United Kingdom",
      firstName: "Viacheslav",
      id: "4597ffc8-39d1-413a-bdb7-c9c5d8ad9c34",
      lastName: "Tester",
      line1: "Lon, Nantmel",
      line2: "",
      postCode: "LD6 5PE",
      title: "",
    },
  },
];

export const paymentCardPropsMocked: PaymentCardPropsExtended = {
  billingAddressTitle: "Billing Address",
  maskedPan: "23234554******1234",
  cardSchema: "Visa Classic",
  expirationDate: "22-07",
  billingAddress: {
    city: "London",
    line1: "2 Finsbury Ave",
    line2: "",
    postCode: "EC2M 2PG",
  },
};
