"use client";
import React, { VFC } from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { NavItemProps } from "~/bff/components/NavItem";
import { CombinedLink } from "~/components/combined-link/component";
import { Image } from "~/components/image/component";
import { prepareTextAttribute } from "~/helpers/prepare-text-attribute";
import { getTestAutomationProps } from "~/helpers/test-automation-props";
import { getImageSrc } from "~/utils/image/get-image-src";

import { imageTemplates, NAV_ITEM_COMPONENT_CLASSES } from "./constants";
import { NavItemComponentContainer } from "./styled";

export const navItemComponent = "home-page-navItem";

export const NavItemComponent: VFC<NavItemProps> = (props) => {
  const { title, navMenuImage, meta, urlSlug } = props;

  const { deliveryKey } = meta ?? {};

  const imageProps = {
    source: navMenuImage?.source
      ? getImageSrc(navMenuImage.source)
      : "/assets/images/no-image.png",
    alt: navMenuImage?.alt,
    title: navMenuImage?.title,
  };

  return (
    <CombinedLink
      href={`/${urlSlug}`}
      key={deliveryKey}
      underline="none"
      {...getTestAutomationProps(`${navItemComponent}-link`)}
    >
      <NavItemComponentContainer>
        <Box className={NAV_ITEM_COMPONENT_CLASSES.backgroundImage}>
          <Image
            className={NAV_ITEM_COMPONENT_CLASSES.backgroundImage}
            imageTemplates={imageTemplates}
            src={imageProps.source}
            alt={prepareTextAttribute(imageProps.alt, true)}
            title={prepareTextAttribute(imageProps.title)}
          />
        </Box>
        <Box
          className={NAV_ITEM_COMPONENT_CLASSES.textBlock}
          {...getTestAutomationProps(`${navItemComponent}-text`)}
        >
          <Typography variant="h6" className={NAV_ITEM_COMPONENT_CLASSES.title}>
            {title}
          </Typography>
        </Box>
      </NavItemComponentContainer>
    </CombinedLink>
  );
};
