import React from "react";

import { IconProps } from "./../types";

import { BaseSVGIcon } from "./base-svg-icon";

export const Curve: React.FC<IconProps> = (props) => {
  return (
    <BaseSVGIcon
      width="155"
      height="231"
      fill="none"
      viewBox="0 0 155 231"
      {...props}
    >
      <path
        d="M12.8435 1C4.98418 15.69 -4.17081 52.6353 22.0834 82.8967C54.9012 120.723 66.371 82.8967 103.331 82.8967C140.291 82.8967 154 100.548 154 127.724C154 154.901 143.158 183.523 110.34 183.523C77.5226 183.523 34.1915 158.917 12.8435 183.523C-4.23495 203.208 0.841616 224.043 5.5147 232"
        stroke="black"
        strokeWidth="2"
        strokeDasharray="4 4"
      />
    </BaseSVGIcon>
  );
};
