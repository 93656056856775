import { ImageTemplates } from "~/components/image/types";
import { WindowFormat } from "~/context/breakpoints/types";

export const imageTemplates: ImageTemplates = {
  [WindowFormat.mobile]: "cccategoriescards-mobile",
  [WindowFormat.tablet]: "cccategoriescards-tablet",
  [WindowFormat.desktop]: "cccategoriescards-regulardesktop",
  [WindowFormat.wideDesktop]: "cccategoriescards-largedesktop",
};

export const CLICK_AND_COLLECT_CATEGORY_CARDS_PREFIX =
  "ClickAndCollectCategoryCards";

export const CLICK_AND_COLLECT_CATEGORY_CARDS_CLASSES = {
  eyebrow: `${CLICK_AND_COLLECT_CATEGORY_CARDS_PREFIX}-eyebrow`,
  title: `${CLICK_AND_COLLECT_CATEGORY_CARDS_PREFIX}-title`,
  cardTitle: `${CLICK_AND_COLLECT_CATEGORY_CARDS_PREFIX}-cardTitle`,
  backgroundImage: `${CLICK_AND_COLLECT_CATEGORY_CARDS_PREFIX}-backgroundImage`,
  buttonContainer: `${CLICK_AND_COLLECT_CATEGORY_CARDS_PREFIX}-buttonContainer`,
  button: `${CLICK_AND_COLLECT_CATEGORY_CARDS_PREFIX}-button`,
};
