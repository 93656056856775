"use client";
import React from "react";

import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import { Image } from "~/components/image/component";
import { Markdown } from "~/components/markdown/component";
import { prepareTextAttribute } from "~/helpers/prepare-text-attribute";
import { getTestAutomationProps } from "~/helpers/test-automation-props";
import { Curve } from "~/theme/icons/components/curve";
import { getFormatMedia } from "~/theme/utils";
import { getImageSrc } from "~/utils/image/get-image-src";

import { CombinedButton } from "../combined-button/component";

import {
  bannerBackgroundImageTemplates,
  bannerImageTemplates,
  CLICK_AND_COLLECT_HERO_BANNER_CLASSES,
  logoImageTemplates,
} from "./constants";
import { ClickAndCollectHeroBannerContainer } from "./styled";
import type { ClickAndCollectHeroBannerProps } from "./types";

export const testIds = {
  container: "click-and-collect-hero-banner",
  bannerTitle: "cc-banner-title",
  bannerButton: "cc-banner-button",
  storesText: "cc-stores-text",
};

export const ClickAndCollectHeroBanner = (
  props: ClickAndCollectHeroBannerProps,
): React.ReactElement => {
  const { backgroundImage, logoImage, title, images, button } = props ?? {};
  const theme = useTheme();

  const { BREAKPOINT_TABLET, BREAKPOINT_DESKTOP } = getFormatMedia(theme);
  const isDesktop = useMediaQuery(BREAKPOINT_DESKTOP);
  const isMobile = !useMediaQuery(BREAKPOINT_TABLET);

  return (
    <ClickAndCollectHeroBannerContainer
      {...getTestAutomationProps(testIds.container)}
      id={testIds.container}
    >
      <Box className={CLICK_AND_COLLECT_HERO_BANNER_CLASSES.heroBanner}>
        <Box className={CLICK_AND_COLLECT_HERO_BANNER_CLASSES.bannerImageWrapper}>
          <Image
            className={CLICK_AND_COLLECT_HERO_BANNER_CLASSES.bannerBackground}
            src={getImageSrc(backgroundImage?.source ?? undefined)}
            alt={prepareTextAttribute(backgroundImage?.alt, true)}
            title={prepareTextAttribute(backgroundImage?.title)}
            imageTemplates={bannerBackgroundImageTemplates}
            fetchPriority="high"
          />
        </Box>
        <Box className={CLICK_AND_COLLECT_HERO_BANNER_CLASSES.leftSection}>
          <Image
            alt=""
            className={CLICK_AND_COLLECT_HERO_BANNER_CLASSES.logoImage}
            src={getImageSrc(logoImage?.source ?? undefined)}
            title={prepareTextAttribute(logoImage?.source?.name)}
            imageTemplates={logoImageTemplates}
          />

          <Typography
            variant="h1"
            component="h1"
            className={CLICK_AND_COLLECT_HERO_BANNER_CLASSES.bannerTitle}
            {...getTestAutomationProps(testIds.bannerTitle)}
          >
            {title}
          </Typography>

          {!isMobile && (
            <CombinedButton
              size="large"
              variant="contained"
              href={button?.url ?? ""}
              className={CLICK_AND_COLLECT_HERO_BANNER_CLASSES.bannerButton}
              classes={{
                root: CLICK_AND_COLLECT_HERO_BANNER_CLASSES.bannerButtonLabel,
              }}
              {...getTestAutomationProps(testIds.bannerButton)}
            >
              {button?.text}
            </CombinedButton>
          )}
        </Box>

        <Box className={CLICK_AND_COLLECT_HERO_BANNER_CLASSES.rightSection}>
          {images?.map((image, index) => (
            <Box
              className={CLICK_AND_COLLECT_HERO_BANNER_CLASSES.bannerImageBlock}
              key={image?.image?.source?.id}
            >
              {index === 1 && isDesktop && <Curve />}
              <Image
                alt=""
                className={CLICK_AND_COLLECT_HERO_BANNER_CLASSES.bannerImage}
                src={getImageSrc(image?.image?.source ?? undefined)}
                title={prepareTextAttribute(image?.image?.source?.name)}
                imageTemplates={bannerImageTemplates}
              />
              <Markdown
                className={CLICK_AND_COLLECT_HERO_BANNER_CLASSES.bannerImageDesc}
                text={image?.imageDescription ?? ""}
              />
            </Box>
          ))}
        </Box>

        {isMobile && (
          <CombinedButton
            size="large"
            variant="contained"
            href={button?.url ?? ""}
            className={CLICK_AND_COLLECT_HERO_BANNER_CLASSES.bannerButton}
            classes={{
              root: CLICK_AND_COLLECT_HERO_BANNER_CLASSES.bannerButtonLabel,
            }}
            {...getTestAutomationProps(testIds.bannerButton)}
          >
            {button?.text}
          </CombinedButton>
        )}
      </Box>
    </ClickAndCollectHeroBannerContainer>
  );
};
