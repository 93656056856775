"use client";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { FontWeightProperty } from "csstype";

import { getFormatMedia } from "~/theme/utils";

import { CLICK_AND_COLLECT_CATEGORY_CARDS_CLASSES } from "./constants";

export const ClickAndCollectCategoryCardsContainer = styled(Box)(({ theme }) => {
  const { BREAKPOINT_NARROW_PHONE, BREAKPOINT_TABLET, BREAKPOINT_DESKTOP } =
    getFormatMedia(theme);
  return {
    padding: theme.spacing(0, 2, 7),
    [BREAKPOINT_TABLET]: {
      padding: theme.spacing(0, 4, 9),
    },
    [BREAKPOINT_DESKTOP]: {
      padding: theme.spacing(0, 8, 16),
    },
    [`& .${CLICK_AND_COLLECT_CATEGORY_CARDS_CLASSES.eyebrow}`]: {
      ...theme.typography.body1,
      marginBottom: theme.spacing(1),
      [BREAKPOINT_DESKTOP]: {
        marginBottom: theme.spacing(2),
      },
    },
    [`& .${CLICK_AND_COLLECT_CATEGORY_CARDS_CLASSES.title}`]: {
      [BREAKPOINT_NARROW_PHONE]: {
        ...theme.typography.h3,
        marginBottom: theme.spacing(2),
      },
      [BREAKPOINT_TABLET]: {
        ...theme.typography.h2,
        marginBottom: theme.spacing(4),
      },
      [BREAKPOINT_DESKTOP]: {
        ...theme.typography.h1,
        marginBottom: theme.spacing(4),
      },
    },
    [`& .${CLICK_AND_COLLECT_CATEGORY_CARDS_CLASSES.cardTitle}`]: {
      display: "block",
      fontWeight: theme.typography.fontWeightBold as FontWeightProperty,
      margin: `${theme.spacing(2)} auto ${theme.spacing(2)} auto`,
      [BREAKPOINT_DESKTOP]: {
        margin: `${theme.spacing(2)} auto 0 auto`,
        fontSize: 18,
      },
    },
    [`& .${CLICK_AND_COLLECT_CATEGORY_CARDS_CLASSES.backgroundImage}`]: {
      display: "block",
      position: "static",
      aspectRatio: "3 / 4",
      width: "100%",
      margin: "0",
      objectFit: "cover",
      objectPosition: "top",
    },
    [`& .${CLICK_AND_COLLECT_CATEGORY_CARDS_CLASSES.buttonContainer}`]: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    [`& .${CLICK_AND_COLLECT_CATEGORY_CARDS_CLASSES.button}`]: {
      width: "100%",
      marginTop: theme.spacing(1),
      fontWeight: theme.typography.fontWeightBold as FontWeightProperty,
      height: "auto",
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      [BREAKPOINT_TABLET]: {
        width: "auto",
        display: "flex",
        alignItems: "center",
        marginTop: theme.spacing(2),
      },
      [BREAKPOINT_DESKTOP]: {
        marginTop: theme.spacing(4),
      },
    },
  };
});
